import "../../styles/aboutUs.css";
import { aboutUsContent } from "../../content/aboutUsContent";

const AboutUs = () => {
  return (
    <section className="aboutUs" id="about-scroll">
      <h2 className="aboutUs__title">About Us</h2>
      <p className="aboutUs__content">{aboutUsContent}</p>
    </section>
  );
};

export default AboutUs;
