import React, { useEffect, useState } from "react";
import "../../views/App/App.css";
import preloaderImg from "../../assets/images/logo.png";
import Container from "../../components/Container";
import { residetialContent } from "content/residentialContent";
import SecondHeader from "../../components/SecondHeader";
import Header from "components/Header/Header";
import MobileHeader from "components/MobileHeader";
import ResidentialSection from "components/ResidentialSection";
import ModalForm from "components/ModalForm";
import Footer from "../../components/Footer";

const Commercial = () => {
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPreloader(false);
    }, 2000);
  }, []);

  if (preloader) {
    return (
      <div className="preloader">
        <img className="preloader__img" src={preloaderImg} alt="Loading..." />
      </div>
    );
  }

  return (
    <>
      <Header />
      <MobileHeader />
      <Container>
        <SecondHeader headerText={residetialContent.header} />
        <ResidentialSection data={residetialContent.firstSection} />
        <ResidentialSection data={residetialContent.secondSection} />
        <ResidentialSection data={residetialContent.thirthSection} />
        <ModalForm />
      </Container>
      <Footer />
    </>
  );
};

export default Commercial;
