import React from "react";
import "../../styles/secondHeader.css";
import { NavLink } from 'react-router-dom';


const SecondHeader = (props) => {
  return (
    <header className="secondHeader">
      <div className="secondHeader__link">
        <NavLink to='/'>Main</NavLink><span> /{props.headerText}</span>
      </div>
    </header>
  );
};

export default SecondHeader;