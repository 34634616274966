import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import preloaderImg from '../../assets/images/logo.png';
import Container from '../../components/Container';
import AboutUs from '../../components/AboutUs';
import Footer from '../../components/Footer';
import Main from '../../components/Main/Main';
import NavigationRight from '../../components/NavigationRight';

const App = () => {
    const [preloader, setPreloader] = useState(true);

    const { state } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            setPreloader(false);
        }, 2000);
    }, []);

    useEffect(() => {
        if (state) {
            setTimeout(() => {
                document
                    .getElementById(state)
                    .scrollIntoView({ behavior: 'smooth' });
                window.history.replaceState({}, document.title);
            }, 2500);
        }
    }, [state]);

    if (preloader) {
        return (
            <div className="preloader">
                <img
                    className="preloader__img"
                    src={preloaderImg}
                    alt="Loading..."
                />
            </div>
        );
    }

    return (
        <>
            <Main />
            <Container>
                <main>
                    <AboutUs />
                    <NavigationRight />
                </main>
            </Container>
            <Footer />
        </>
    );
};

export default App;
