import React, { useContext } from "react";
import "../../styles/commercialSection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
// import { NavLink } from "react-router-dom";
import ContextForm from "contexts/ContextForm";
import { BiTimeFive } from "react-icons/bi";
import {
  //  FiArrowDownLeft, 
  FiArrowDownRight
} from "react-icons/fi";

const CommercialSection = ({ data }) => {
  let modal = useContext(ContextForm);

  const pagination = {
    clickable: true,
  };

  const autoplay = {
    delay: 2500,
    disableOnInteraction: false,
  };

  const showSliders = data.slides.map((item, index) => {
    return (
      <SwiperSlide key={index}>
        <div
          className="commercialSection__slider_item"
          style={{ backgroundImage: `url(./img/${item})` }}
        ></div>
      </SwiperSlide>
    );
  });

  return (
    <section className="commercialSection">
      <div className="commercialSection__titleBox">
        <h2 className="commercialSection__title">{data.title}</h2>
        <h3 className="commercialSection__subTitle">{data.subTitle}</h3>
      </div>
      <div className="commercialSection__infoBox">
        <div className="commercialSection__slider">
          <Swiper
            pagination={pagination}
            autoplay={autoplay}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            {showSliders}
          </Swiper>
        </div>
        <div className="commercialSection__info">
          <div className="commercialSection__info-title">
            <span className="commercialSection__info-title-icon">
              <BiTimeFive size={64} style={{ color: "#c4c4c4" }} />
            </span>
            <span className="commercialSection__info-title-text">SCHEDULE</span>
          </div>
          <div className="commercialSection__info-time">
            <span className="commercialSection__info-time_title">
              <div className="commercialSection__info-time_preTitle">
                {data.schedule.preTitle}
              </div>
              {data.schedule.title}
            </span>
            <span className="commercialSection__info-time_value">
              {data.schedule.titleValue}
            </span>
          </div>
          <div className="commercialSection__info-subtitle">
            <div className="commercialSection__info-subtitle_title">
              {data.schedule.subTitle}
            </div>
            <div className="commercialSection__info-subtitle_text">
              {data.schedule.subTitleValue}
              <div>{data.schedule.subTitleValue2}</div>
            </div>
          </div>
          <div className="commercialSection__info-description">
            {data.schedule.text}
          </div>
          <div className="commercialSection__info-bottomBox">
            <div className="commercialSection__info-address">
              <div className="commercialSection__info-address_title">
                {data.address.title}
              </div>
              <div className="commercialSection__info-address_text">
                {data.address.text}
              </div>
            </div>
            {/* <div className="commercialSection__info-button">
              <NavLink to={data.buttonFirst.link}>
                {data.buttonFirst.title}
                <FiArrowDownLeft />
              </NavLink>
            </div> */}
          </div>
        </div>
      </div>
      <div className="commercialSection__descriptionBox">
        <div className="commercialSection__descriptionBox_button">
          <button onClick={modal.action}>
            <div className="commercialSection__descriptionBox_button-text">
              {data.buttonSecond.title}
            </div>
            <div className="commercialSection__descriptionBox_button-icon">
              <FiArrowDownRight fontSize={54} />
            </div>
          </button>
        </div>
        <div className="commercialSection__descriptionBox_description">
          <p className="commercialSection__descriptionBox_description-text">
            {data.descriptionTop}
          </p>
          <p className="commercialSection__descriptionBox_description-text">
            {data.descriptionMiddle}
          </p>
          <p className="commercialSection__descriptionBox_description-text">
            {data.descriptionBottom}
          </p>
        </div>
        <div
          className="commercialSection__descriptionBox_img"
          style={{ backgroundImage: `url(./img/${data.descriptionImg})` }}
        ></div>
      </div>
    </section>
  );
};

export default CommercialSection;
