import * as axios from "axios";

export const sendMessage = async (captcha, email, phone, first_name, last_name, message) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/contact`,
      { captcha, email, phone, first_name, last_name, message }
    );
    return response;
  } catch (e) {
    return e?.response;
  }
};