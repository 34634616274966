import React, { useState, useEffect } from "react";
import "../../styles/navigationRight.css";
import Link from "react-scroll/modules/components/Link";
import { IoIosArrowDropup } from "react-icons/io";

const NavigationRight = () => {
  const [offset, setOffset] = useState(0);
  const [widthScreen, setWidthScreen] = useState(0);

  useEffect(() => {
    setWidthScreen(window.screen.width);
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  return offset > 500 ? (
    <div className="navigationRight__up">
      <Link
        className="navigationRight__link"
        activeClass="active"
        to={widthScreen > 996 ? "home-scroll" : "home-scroll-toggle"}
        spy={true}
        smooth={true}
        duration={2000}
        delay={100}
      >
        <IoIosArrowDropup size={64} />
      </Link>
    </div>
  ) : null;
};

export default NavigationRight;
