import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../styles/slider.css';
import { mainContent } from '../../content/mainContent';
import { NavLink } from 'react-router-dom';

export default function Slider() {
    const pagination = {
        clickable: true,
    };

    const autoplay = {
        delay: 2500,
        disableOnInteraction: false,
    };

    const showSliders = mainContent.map(item => {
        return (
            <SwiperSlide key={item.id}>
                <div
                    className="slider__screen"
                    style={{ backgroundImage: `url(./img/${item.slideBg})` }}
                >
                    <div className="slider__container">
                        <h1 className="slider__title">{item.titleTop}</h1>
                        <h1
                            className="slider__title"
                            style={{ marginBottom: '20px' }}
                        >
                            {item.titleBottom}
                        </h1>
                        <p className="slider__subtitle">{item.subtitle}</p>
                        <NavLink className="slider__link" to={item.link}>
                            {item.linkText}
                        </NavLink>
                    </div>
                </div>
            </SwiperSlide>
        );
    });

    return (
        <>
            <div className="slider">
                <Swiper
                    pagination={pagination}
                    autoplay={autoplay}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                >
                    {showSliders}
                </Swiper>
            </div>
        </>
    );
}
