import React from "react";
import "./../../styles/main.css";
import Slider from "./Slider";
import MainBanner from "./MainBanner";
import MobileHeader from "components/MobileHeader";

const Main = () => {

  return (
    <>
      <section className="main">
        <MainBanner />
      </section>
      <MobileHeader />
      <Slider />
    </>

  );
};

export default Main;
