export const mainContent = [
  {
    id: 1,
    titleTop: "Commercial",
    titleBottom: "property",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatum, rerum.",
    link: "/commercial",
    linkText: "View",
    slideBg: "mainBg1.jpg",
    mainBg: "mainBg1.jpg",
  },
  {
    id: 2,
    titleTop: "Residential",
    titleBottom: "property",
    subtitle:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatum, rerum.",
    link: "/residential",
    linkText: "View",
    slideBg: "mainBg2.jpg",
    mainBg: "mainBg2.jpg",
  },
];
