import React, { useContext } from "react";
import "../../styles/residentialSection.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
//import { NavLink } from "react-router-dom";
import ContextForm from "contexts/ContextForm";
import { BiBuildingHouse } from 'react-icons/bi';
import {
    // FiArrowDownLeft, 
    FiArrowDownRight
} from "react-icons/fi";
import { MdBed } from 'react-icons/md';
import { TbBath } from 'react-icons/tb';
import { RiLayoutMasonryLine } from 'react-icons/ri';

const ResidentialSection = ({ data }) => {
    let modal = useContext(ContextForm);

    const pagination = {
        clickable: true,
    };

    const autoplay = {
        delay: 2500,
        disableOnInteraction: false,
    };

    const showSliders = data.slides.map((item, index) => {
        return (
            <SwiperSlide key={index}>
                <div
                    className="residentialSection__slider_item"
                    style={{ backgroundImage: `url(./img/${item})` }}
                ></div>
            </SwiperSlide>
        );
    });

    return (
        <section className="residentialSection">
            <div className="residentialSection__titleBox">
                <h2 className="residentialSection__title">{data.title}</h2>
                <h3 className="residentialSection__subTitle">{data.subTitle}</h3>
            </div>
            <div className="residentialSection__infoBox">
                <div className="residentialSection__slider">
                    <Swiper
                        pagination={pagination}
                        autoplay={autoplay}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {showSliders}
                    </Swiper>
                </div>
                <div className="residentialSection__info">
                    <div className="residentialSection__info-title">
                        <span className="residentialSection__info-title-icon">
                            <BiBuildingHouse size={64} style={{ color: "#c4c4c4" }} />
                        </span>
                        <span className="residentialSection__info-title-text">DESCRIPTION:</span>
                    </div>
                    <div className="residentialSection__info-time">
                        <span className="residentialSection__info-time_title">
                            {data.type.title}
                        </span>
                        <span className="residentialSection__info-time_value">
                            {data.type.titleValue}
                        </span>
                    </div>
                    <div className="residentialSection__info-subtitle">
                        <div className="residentialSection__info-subtitle_title">
                            {data.type.subTitle}
                        </div>
                        <div className="residentialSection__info-subtitle_text">
                            {data.type.subTitleValue}
                            <div>{data.type.subTitleValue2}</div>
                        </div>
                    </div>
                    <div className="residentialSection__info-description">
                        <div className="residentialSection__info-description_column">
                            <div className="residentialSection__info-description_icon">
                                <RiLayoutMasonryLine />
                            </div>
                            <div className="residentialSection__info-description_text">
                                {data.type.rooms.area.name}
                            </div>
                            <div className="residentialSection__info-description_value">
                                {data.type.rooms.area.value}
                            </div>
                        </div>
                        <div className="residentialSection__info-description_column">
                            <div className="residentialSection__info-description_icon">
                                <MdBed />
                            </div>
                            <div className="residentialSection__info-description_text">
                                {data.type.rooms.bedrooms.name}
                            </div>
                            <div className="residentialSection__info-description_value">
                                {data.type.rooms.bedrooms.value}
                            </div>
                        </div>
                        <div className="residentialSection__info-description_column">
                            <div className="residentialSection__info-description_icon">
                                <TbBath />
                            </div>
                            <div className="residentialSection__info-description_text">
                                {data.type.rooms.bathrooms.name}
                            </div>
                            <div className="residentialSection__info-description_value">
                                {data.type.rooms.bathrooms.value}
                            </div>
                        </div>
                    </div>
                    <div className="residentialSection__info-bottomBox">
                        {/* <div className="residentialSection__info-button">
                            <NavLink to={data.buttonFirst.link}>
                                {data.buttonFirst.title}
                                <FiArrowDownLeft />
                            </NavLink>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="residentialSection__descriptionBox">
                <div className="residentialSection__descriptionBox_button">
                    <button onClick={modal.action}>
                        <div className="residentialSection__descriptionBox_button-text">
                            {data.buttonSecond.title}
                        </div>
                        <div className="residentialSection__descriptionBox_button-icon">
                            <FiArrowDownRight fontSize={54} />
                        </div>
                    </button>
                </div>
                <div className="residentialSection__descriptionBox_description">
                    <p className="residentialSection__descriptionBox_description-text">
                        {data.descriptionTop}
                    </p>
                    <p className="residentialSection__descriptionBox_description-text">
                        {data.descriptionBottom}
                    </p>
                </div>
                <div
                    className="residentialSection__descriptionBox_img"
                    style={{ backgroundImage: `url(./img/${data.descriptionImg})` }}
                ></div>
            </div>
        </section>
    );
};

export default ResidentialSection;