export const footerContent = {
  logo: "Emirates.Law",
  links: [
    { id: 1, name: "Commercial property", link: "/commercial" },
    { id: 2, name: "Residential property", link: "/residential" },
  ],
  tel: "802.522.2965",
  email: "info@VTdevelopment.com",
  address:
    "70 Landmark Hill Dr, Brattleboro, VT 05301, USA 9 am - 5 pm mon-fri",
  description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate reiciendis minus nobis molestiae! Quisquam, odit doloremque? Eos ea deserunt hic ducimus laboriosam velit sed, quisquam quos obcaecati dolore placeat voluptatem exercitationem dignissimos dicta nulla cumque tempore. Eum pariatur sunt earum maiores odio repellat fugit rem quod nesciunt recusandae aspernatur quibusdam modi voluptatum in, delectus nobis explicabo adipisci. Dolore dolorem praesentium labore natus, vel, inventore enim impedit culpa eos reprehenderit officiis. Accusantium iusto pariatur nulla vero. Eum possimus dolorum eos, atque repudiandae neque? Illum sunt ab dolores beatae eligendi! Consequuntur cumque expedita id molestias porro. Rerum perferendis ullam nisi neque reiciendis sequi nesciunt asperiores nobis deleniti similique cum assumenda fugiat earum rem quaerat nihil suscipit et, iste veniam dignissimos vero! Velit laudantium reiciendis porro accusantium natus earum necessitatibus ullam ut ducimus? Beatae similique facere sed iste numquam quaerat soluta vitae, sint dolorum harum impedit maiores ut consequatur commodi labore itaque fugit facilis quibusdam saepe, recusandae hic porro repellat ipsa? Quam, provident autem. Adipisci eos numquam tempore? Nesciunt tenetur cupiditate eius voluptatibus totam, quia velit possimus placeat unde dolore officia voluptatum. Possimus molestias voluptas totam deserunt perferendis voluptatum rem veritatis nemo laboriosam quae voluptate eveniet similique sed, quia, aliquid, facilis quaerat assumenda.",
  resived: "2022 ©  All rights reserved.",
  policy: "Privacy Policy",
  dib: "Website by dib.company",
};
