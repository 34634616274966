export const EMAIL_REGEXP =
    // eslint-disable-next-line no-useless-escape
    /^(([A-Za-z0-9\.\+_\s\"]+(\.[A-Za-z0-9\.\+_\s\"]+)*)|(\".+\"))@((\[[0-9 ]{1,3}\.[0-9 ]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9_]+\.)+[a-zA-Z]{2,}))$/;

export const NAME_REGEXP = /^[a-zA-Z-'` ]+$/;

export const PHONE_REGEXP = /^(\+?\d{7,15})$/;

// eslint-disable-next-line no-useless-escape
export const MESSAGE_REGEXP = /^[a-zA-Z0-9-'`\.\+, !?%\^\&\*\(\)=\[\]\~:;@]+$/;
