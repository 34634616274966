import React, { useState, useEffect } from "react";
import "./../../styles/header.css";
import logo from "../../assets/images/logo.png";
import Link from "react-scroll/modules/components/Link";
import { NavLink } from "react-router-dom";
import { mainContent } from "content/mainContent";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [isService, setIsService] = useState(false);
  let { pathname } = useLocation();


  useEffect(() => {
    mainContent.forEach((item) => {
      if (item.link === pathname) {
        setIsService(true);
      }
    });
  }, [pathname]);



  return (
    <header className={isService ? 'header header-pages' : 'header'} id="home-scroll">
      <div className="header__container">
        <div className="header__navbar">
          <div className="header__logo">
            {pathname === "/" ? (
              <>
                <Link
                  activeClass="active"
                  to="home-scroll"
                  spy={true}
                  smooth={true}
                  duration={2000}
                  delay={100}
                >
                  <span className="header__logo_img">
                    <img src={logo} alt="logo" />
                  </span>
                </Link>
              </>
            ) : (
              <>
                <NavLink to="/">
                  <span className="header__logo_img">
                    <img src={logo} alt="logo" />
                  </span>
                </NavLink>
              </>
            )}
          </div>
          {pathname === "/" ? (
            <nav className="header__nav">
              <ul className="header__menu">
                <li>
                  <Link
                    className="header__menu_item"
                    activeClass="active"
                    to="about-scroll"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    delay={100}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    className="header__menu_item"
                    activeClass="active"
                    to="footer-scroll"
                    spy={true}
                    smooth={true}
                    duration={2000}
                    delay={100}
                  >
                    Contacts
                  </Link>
                </li>
                <li>
                  <NavLink
                    className="header__menu_item"
                    to="/commercial"
                  >
                    Commercial
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="header__menu_item"
                    to="/residential"
                  >
                    Residential
                  </NavLink>
                </li>
              </ul>
            </nav>
          ) : (
            <nav className="header__nav">
              <ul className="header__menu">
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemPages"
                        : "header__menu_itemError"
                    }
                    to="/"
                    state="about-scroll"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemPages"
                        : "header__menu_itemError"
                    }
                    to="/"
                    state="footer-scroll"
                  >
                    Contacts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemPages"
                        : "header__menu_itemError"
                    }
                    to="/commercial"
                  >
                    Commercial
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      isService
                        ? "header__menu_itemPages"
                        : "header__menu_itemError"
                    }
                    to="/residential"
                  >
                    Residential
                  </NavLink>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
