import React from 'react';
import Link from 'react-scroll/modules/components/Link';
import { NavLink, useLocation } from 'react-router-dom';

const Links = ({ setChecked }) => {

    let { pathname } = useLocation();


    return (
        <>
            {pathname === "/" ? (
                <>
                    <li>
                        <Link
                            activeClass="active"
                            to="about-scroll"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            delay={100}
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="footer-scroll"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            delay={100}
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            Contacts
                        </Link>
                    </li>
                    <li>
                        <NavLink
                            to="/commercial"
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            Commercial
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/residential"
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            Residential
                        </NavLink>
                    </li>
                </>
            ) : (
                <>
                    <li>
                        <NavLink
                            to="/"
                            state="about-scroll"
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            About Us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/"
                            state="footer-scroll"
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            Contacts
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/commercial"
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            Commercial
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/residential"
                            onClick={() => {
                                setChecked(false);
                            }}
                        >
                            Residential
                        </NavLink>
                    </li>
                </>
            )}
        </>
    );
};

export default Links;
