import React, { useState } from "react";
import Link from "react-scroll/modules/components/Link";
import { NavLink, useLocation } from "react-router-dom";
import logo from '../../assets/images/logo.png';
import Links from "components/Main/Links";



const MobileHeader = () => {

  const [checked, setChecked] = useState(false);
  const { pathname } = useLocation();


  const onShowMenu = (event) => {
    setChecked(event.target.checked);
  };

  return (<>
    <section className="main-slider" id="home-scroll-toggle">
      <div
        className="header-toggle"
        style={checked ? { backgroundColor: "#fff" } : null}
      >
        <div className="logo">
          <div className="logo-box">
            {
              pathname === '/' ?
                <Link
                  activeClass="active"
                  to="home-scroll-toggle"
                  spy={true}
                  smooth={true}
                  duration={2000}
                  delay={100}
                  onClick={() => {
                    setChecked(false);
                  }}
                >
                  <img
                    width={100}
                    className="logo-img"
                    src={logo}
                    alt="logo"
                  />
                </Link>
                :
                <NavLink to='/'>
                  <img
                    width={100}
                    className="logo-img"
                    src={logo}
                    alt="logo"
                  />
                </NavLink>
            }

          </div>
        </div>
        <input
          className="side-menu"
          type="checkbox"
          id="side-menu"
          onChange={onShowMenu}
          checked={checked}
        />
        <label className="hamb" htmlFor="side-menu">
          <span className="hamb-line"></span>
        </label>
        <nav className="nav">
          <ul className="menu">
            <Links setChecked={setChecked} />
          </ul>
        </nav>
      </div>
    </section>
  </>
  );
}

export default MobileHeader;