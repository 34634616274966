import { React, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContextForm from "./contexts/ContextForm";
import Home from "./views/App";
import Commercial from "./views/Commercial";
import Residential from "./views/Residential";

const AppRoutes = () => {

  const [modalForm, setModalForm] = useState(false);

  const showModalForm = () => {
    setModalForm(!modalForm);
  };

  const valueContextForm = {
    value: modalForm,
    action: showModalForm,
  };

  return (
    <>
      <ContextForm.Provider value={valueContextForm}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/commercial" element={<Commercial />} />
            <Route path="/residential" element={<Residential />} />
          </Routes>
        </Router>
      </ContextForm.Provider>
    </>
  );
};

export default AppRoutes;
