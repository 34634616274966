import React, { useContext, useState } from 'react';
import '../../styles/modalForm.css';
import ContextForm from '../../contexts/ContextForm';
import CustomModal from './CustomModal';
import { sendMessage } from 'api/sendForm';
import {
    EMAIL_REGEXP,
    NAME_REGEXP,
    PHONE_REGEXP,
    MESSAGE_REGEXP,
} from '../../helpers/regexp';

const ModalForm = () => {
    let modal = useContext(ContextForm);
    const [srcCaptcha, setSrcCaptcha] = useState(
        `${process.env.REACT_APP_BASE_URL}/get-captcha`,
    );
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errorFirstName, setErrorFirstName] = useState(true);
    const [errorLastName, setErrorLastName] = useState(true);
    const [emptyFirstName, setEmptyFirstName] = useState(false);
    const [emptyLastName, setEmptyLastName] = useState(false);
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(true);
    const [emptyEmail, setEmptyEmail] = useState(false);
    const [phone, setPhone] = useState('');
    const [errorPhone, setErrorPhone] = useState(true);
    const [emptyPhone, setEmptyPhone] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [emptyCaptcha, setEmptyCaptcha] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(true);
    const [emptyMessage, setEmptyMessage] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [showSuccessBlock, setShowSuccessBlock] = useState(false);

    const onClose = () => {
        modal.action(false);
        onReloadCaptcha();
    };

    const onReloadCaptcha = () => {
        setSrcCaptcha(srcCaptcha + `?r=${new Date().getUTCMilliseconds()}`);
    };

    const validateEmail = value => {
        return EMAIL_REGEXP.test(value);
    };

    const validateName = value => {
        return NAME_REGEXP.test(value);
    };

    const validatePhone = value => {
        return PHONE_REGEXP.test(value);
    };

    const validateMessage = value => {
        return MESSAGE_REGEXP.test(value);
    };

    const onFirstName = event => {
        setFirstName(event.target.value);
        validateName(event.target.value)
            ? setErrorFirstName(false)
            : setErrorFirstName(true);
    };

    const onLastName = event => {
        setLastName(event.target.value);
        validateName(event.target.value)
            ? setErrorLastName(false)
            : setErrorLastName(true);
    };

    const onEmail = event => {
        setEmail(event.target.value);
        validateEmail(event.target.value)
            ? setErrorEmail(false)
            : setErrorEmail(true);
    };

    const onPhone = event => {
        // eslint-disable-next-line no-useless-escape
        let value = event.target.value.replace(/[^\+\d]/g, '');
        setPhone(value);
        validatePhone(value) ? setErrorPhone(false) : setErrorPhone(true);
    };

    const onCaptcha = event => {
        setCaptcha(event.target.value);
    };

    const onMessage = event => {
        setMessage(event.target.value);
        validateMessage(event.target.value)
            ? setErrorMessage(false)
            : setErrorMessage(true);
    };

    const onSend = () => {
        if (
            errorFirstName === false &&
            errorLastName === false &&
            firstName.trim().length > 0 &&
            lastName.trim().length > 0 &&
            errorEmail === false &&
            errorPhone === false &&
            captcha.trim().length > 0 &&
            message.trim().length > 0 &&
            errorMessage === false
        ) {
            setDisableButton(true);
            sendMessage(
                captcha,
                email,
                phone,
                firstName,
                lastName,
                message,
            ).then(res => {
                if (res.data.status === true) {
                    setShowSuccessBlock(true);
                    setTimeout(() => {
                        setShowSuccessBlock(false);
                        onClose();
                    }, 5000);
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setCaptcha('');
                    setMessage('');
                    setDisableButton(false);
                    onReloadCaptcha();
                } else if (res.data.status === false) {
                    setDisableButton(false);
                    setEmptyCaptcha(true);
                    setTimeout(() => {
                        setDisableButton(false);
                        setEmptyCaptcha(false);
                    }, 5000);
                } else {
                    console.warn(res);
                }
            });
        } else if (errorFirstName === true || firstName.trim().length === 0) {
            setEmptyFirstName(true);
            setTimeout(() => {
                setEmptyFirstName(false);
            }, 2000);
        } else if (errorLastName === true || lastName.trim().length === 0) {
            setEmptyLastName(true);
            setTimeout(() => {
                setEmptyLastName(false);
            }, 2000);
        } else if (errorEmail === true || email.trim().length === 0) {
            setEmptyEmail(true);
            setTimeout(() => {
                setEmptyEmail(false);
            }, 2000);
        } else if (errorPhone === true || phone.trim().length === 0) {
            setEmptyPhone(true);
            setTimeout(() => {
                setEmptyPhone(false);
            }, 2000);
        } else if (captcha.trim().length === 0) {
            setEmptyCaptcha(true);
            setTimeout(() => {
                setEmptyCaptcha(false);
            }, 2000);
        } else if (errorMessage === true || message.trim().length === 0) {
            setEmptyMessage(true);
            setTimeout(() => {
                setEmptyMessage(false);
            }, 2000);
        }
    };

    return (
        <>
            <CustomModal
                visible={modal.value}
                title="REQUEST A CALL BACK"
                footer={
                    <button onClick={onSend} disabled={disableButton}>
                        Send
                    </button>
                }
                onClose={onClose}
                srcCaptcha={srcCaptcha}
                onReloadCaptcha={onReloadCaptcha}
                onFirstName={onFirstName}
                onLastName={onLastName}
                onEmail={onEmail}
                onPhone={onPhone}
                onCaptcha={onCaptcha}
                onMessage={onMessage}
                firstName={firstName}
                lastName={lastName}
                email={email}
                phone={phone}
                captcha={captcha}
                message={message}
                emptyCaptcha={emptyCaptcha}
                emptyFirstName={emptyFirstName}
                emptyLastName={emptyLastName}
                emptyEmail={emptyEmail}
                emptyPhone={emptyPhone}
                emptyMessage={emptyMessage}
                disableButton={disableButton}
                showSuccessBlock={showSuccessBlock}
            />
        </>
    );
};

export default ModalForm;
