import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import '../../styles/mainBanner.css';
import { mainContent } from '../../content/mainContent';
import ContextForm from '../../contexts/ContextForm';
import Header from '../Header/Header';

const MainBanner = () => {
    let modal = useContext(ContextForm);

    let showMainContent = mainContent.map(item => {
        return (
            <div key={item.id} className="main__item">
                <SwiperSlide key={item.id}>
                    <img src={`./img/${item.mainBg}`} alt="bg" />
                    <div className="main__item_content">
                        <h3 className="main__item_title">{item.titleTop}</h3>
                        <h3 className="main__item_title">{item.titleBottom}</h3>
                        <p className="main__item_subtitle">{item.subtitle}</p>
                        <a
                            className="main__item_link"
                            href={item.link}
                            onClick={modal.dispatch}
                        >
                            {item.linkText}
                        </a>
                    </div>
                </SwiperSlide>
            </div>
        );
    });

    return (
        <>
            <Swiper
                simulateTouch={false}
                slidesPerView={2}
                className="mySwiper main__item_bg"
            >
                <div className="main__item_header">
                    <Header />
                </div>
                {showMainContent}
            </Swiper>
        </>
    );
};

export default MainBanner;
