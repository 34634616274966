export const commercialContent = {
  header: "Commercial property",
  firstSection: {
    id: 1,
    title: "VIB — BRATTLEBORO",
    subTitle: "THE MOST EXCITING WORK \n SPACES IN BRATTLEBORO",
    slides: ["s1Slide1.jpg", "s1Slide2.jpg", "s1Slide3.jpg"],
    schedule: {
      preTitle: null,
      title: "M - F:",
      titleValue: "9AM - 5PM",
      subTitle: "Weekend Access:",
      subTitleValue: "MEMBERSHIP ONLY",
      subTitleValue2: null,
      text: "WE HAVE OVER 18,000 SQ FT OF HISTORIC CREATIVE SPACE LOCATED JUST ON THE EDGE OF DOWNTOWN BRATTLEBORO, VT IN A SPACIOUS OLD MANSION",
    },
    address: {
      title: "Address",
      text: "70 LANDMARK HILL DR, BRATTLEBORO, VT 05301",
    },
    buttonFirst: {
      title: "EXPLORE THIS PLACE",
      link: "/",
    },
    buttonSecond: {
      title: "JOIN",
      link: "/",
    },
    descriptionTop:
      "We are a collective group of people who are looking to help push business, individuals, and ideas to the next level. We are interested in building, investing, and growing the community and also creating an innovation hub of creative like-minded people.",
    descriptionMiddle: null,
    descriptionBottom:
      "What better place than right here in southern Vermont! We have seasons, skiing, the CT river, good food, culture, arts, and local brews all surrounded by well rounded and interesting people.",
    descriptionImg: "s1Description.jpg",
  },
  secondSection: {
    id: 2,
    title: "VIB — DOWNTOWN BRATTLEBORO",
    subTitle: "THE MOST EXCITING WORK \n SPACES IN BRATTLEBORO",
    slides: ["s2Slide1.jpg", "s2Slide2.jpg", "s2Slide3.jpg"],
    schedule: {
      preTitle: null,
      title: null,
      titleValue: null,
      subTitle: null,
      subTitleValue: "MEMBERSHIP ONLY",
      subTitleValue2: "24/7 ACCESS",
      text: "WE HAVE OVER 2,000 SQ FT OF PRIVATE OFFICES LOCATED IN THE HEART OF DOWNTOWN BRATTLEBORO, VT",
    },
    address: {
      title: "Address",
      text: "80 FLAT ST UNIT #103 BRATTLEBORO VT 05301",
    },
    buttonFirst: {
      title: "EXPLORE THIS PLACE",
      link: "/",
    },
    buttonSecond: {
      title: "JOIN",
      link: "/",
    },
    descriptionTop:
      "Here, we offer individual offices for a membership only fee with access any time of the day or night. Walk out the door and step on to Main St in downtown Brattleboro in ninety seconds. All locations share access to all other VIB locations in southern Vermont. Being a member at any one of our locations allows you access to our other properties as well as our services to assist with multiple facets of your business.",
    descriptionMiddle: null,
    descriptionBottom:
      "From software development, new business strategies, financials, finding investors, market research, and more. You can host conferences, seminars, training sessions for certifications amongst other misc activities or events. We have up to fiber service at all locations so there is never any lag, all your downloads, uploads and video conferencing will be lightning fast.",
    descriptionImg: "s2Description.jpg",
  },
  thirthSection: {
    id: 3,
    title: "VIB — DOWNTOWN BENNINGTON",
    subTitle: "THE MOST EXCITING WORK \n SPACES IN BENNINGTON",
    slides: ["s3Slide1.jpg", "s3Slide2.jpg", "s3Slide3.jpg"],
    schedule: {
      preTitle: "OPENING Q1 2022",
      title: "M - F:",
      titleValue: "9AM - 5PM",
      subTitle: "Weekend Access:",
      subTitleValue: "MEMBERSHIP ONLY",
      subTitleValue2: null,
      text: "WE HAVE OVER 5,000 SQ FT OF HISTORIC CREATIVE SPACE SMACK IN THE MIDDLE OF DOWNTOWN BENNINGTON, VT",
    },
    address: {
      title: "Address",
      text: "425 MAIN ST BENNINGTON VT 05201",
    },
    buttonFirst: {
      title: "EXPLORE THIS PLACE",
      link: "/",
    },
    buttonSecond: {
      title: "JOIN",
      link: "/",
    },
    descriptionTop:
      "We have services to assist with multiple facets of your business, software development, new business strategies, financials, finding investors, market research, and more. You can host conferences, seminars, training sessions for certifications amongst other misc activities. Help us create and build a community here in Bennington to collaborate with the surrounding area in a thriving constructive environment.",
    descriptionMiddle:
      "Being a member at any one of our locations allows you access to our other properties as well as our services to assist with multiple facets of your business. From software development, new business strategies, financials, finding investors, market research, and more. You can host conferences, seminars, training sessions for certifications amongst other misc activities or events.",
    descriptionBottom:
      "We have up to fiber service at all locations so there is never any lag, all your downloads, uploads and video conferencing will be lightning fast.",
    descriptionImg: "s3Description.jpg",
  },
};
