export const residetialContent = {
    header: "Residential property",
    firstSection: {
        id: 4,
        title: "VT DEVELOPMENT — APPLETON",
        subTitle: "MORE THAN 10 ACTIVE HOUSE PROJECTS FROM VERMONT RE DEVELOPMENT LLC",
        slides: ["s4Slide1.jpg", "s4Slide2.jpg", "s4Slide3.jpg"],
        type: {
            title: "PROPERTY TYPE:",
            titleValue: "Villa/House",
            subTitle: "The space",
            subTitleValue: "Land Size:",
            subTitleValue2: '1315.2 Sq Ft',
            rooms: {
                area: { name: 'Area (Sq Ft)', value: 1440 },
                bedrooms: { name: 'Bedrooms', value: 4 },
                bathrooms: { name: 'Bathrooms', value: 2 },
            }
        },
        buttonFirst: {
            title: "EXPLORE THIS OPTION",
            link: "/",
        },
        buttonSecond: {
            title: "JOIN",
            link: "/",
        },
        descriptionTop:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        descriptionBottom:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        descriptionImg: "s4Description.jpg",
    },
    secondSection: {
        id: 5,
        title: "VT DEVELOPMENT — FAIRFAX II",
        subTitle: "MORE THAN 10 ACTIVE HOUSE PROJECTS FROM VERMONT RE DEVELOPMENT LLC",
        slides: ["s5Slide1.jpg", "s5Slide2.jpg", "s5Slide3.jpg"],
        type: {
            title: "PROPERTY TYPE:",
            titleValue: "Villa/House",
            subTitle: "The space",
            subTitleValue: "Land Size:",
            subTitleValue2: '1315.2 Sq Ft',
            rooms: {
                area: { name: 'Area (Sq Ft)', value: 1230 },
                bedrooms: { name: 'Bedrooms', value: 4 },
                bathrooms: { name: 'Bathrooms', value: 2 },
            }
        },
        buttonFirst: {
            title: "EXPLORE THIS OPTION",
            link: "/",
        },
        buttonSecond: {
            title: "JOIN",
            link: "/",
        },
        descriptionTop:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        descriptionBottom:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        descriptionImg: "s5Description.jpg",
    },
    thirthSection: {
        id: 6,
        title: "VT DEVELOPMENT — ANNANDALE",
        subTitle: "MORE THAN 10 ACTIVE HOUSE PROJECTS FROM VERMONT RE DEVELOPMENT LLC",
        slides: ["s6Slide1.jpg", "s6Slide2.jpg", "s6Slide3.jpg"],
        type: {
            title: "PROPERTY TYPE:",
            titleValue: "Villa/House",
            subTitle: "The space",
            subTitleValue: "Land Size:",
            subTitleValue2: '1096 Sq Ft',
            rooms: {
                area: { name: 'Area (Sq Ft)', value: 1657 },
                bedrooms: { name: 'Bedrooms', value: 3 },
                bathrooms: { name: 'Bathrooms', value: 1.5 },
            }
        },
        buttonFirst: {
            title: "EXPLORE THIS OPTION",
            link: "/",
        },
        buttonSecond: {
            title: "JOIN",
            link: "/",
        },
        descriptionTop:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        descriptionBottom:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        descriptionImg: "s6Description.jpg",
    },
};