import React, { useState, useEffect } from 'react';
import Link from 'react-scroll/modules/components/Link';
import { NavLink } from 'react-router-dom';
import '../../styles/footer.css';
import { footerContent } from '../../content/footerContent';
import logoFooter from '../../assets/images/logo.png';

const Footer = () => {
    const [, setWidthScreen] = useState(0);

    useEffect(() => {
        setWidthScreen(window.screen.width);
    }, []);

    const kindsLeft = footerContent.links.map(
        item =>
            item.id <= 5 && (
                <div key={item.id} className="footer__kinds_item">
                    <NavLink key={item.id} to={item.link}>
                        {item.name}
                    </NavLink>
                </div>
            ),
    );

    return (
        <footer className="footer" id="footer-scroll">
            <div className="footer__container">
                <div className="footer__top">
                    <div className="footer__logo">
                        <Link
                            activeClass="active"
                            to="home-scroll"
                            spy={true}
                            smooth={true}
                            duration={2000}
                            delay={100}
                        >
                            <img
                                className="footer__logo_img"
                                src={logoFooter}
                                alt="logo"
                            />
                        </Link>
                    </div>
                    <div className="footer__kinds">
                        <div className="footer__kinds_left">{kindsLeft}</div>
                    </div>
                    <address className="footer__contacts">
                        <div className="footer__contacts_tel">
                            <a href={'tel:' + footerContent.tel}>
                                {footerContent.tel}
                            </a>
                        </div>
                        <div className="footer__contacts_email">
                            <a href={'mailto:' + footerContent.email}>
                                {footerContent.email}
                            </a>
                        </div>
                        <div className="footer__contacts_address">
                            {footerContent.address}
                        </div>
                    </address>
                </div>
                <div className="footer__middle">
                    {footerContent.description}
                </div>
                <div className="footer__bottom">
                    <time className="footer__resived" dateTime="2022">
                        {footerContent.resived}
                    </time>
                    <a className="footer__policy" href="/#">
                        {footerContent.policy}
                    </a>
                    <div className="footer__dib">
                        <span>
                            {footerContent.dib.split(' ')[0]}{' '}
                            {footerContent.dib.split(' ')[1]}{' '}
                        </span>
                        <a href="https://dib.company/">
                            {footerContent.dib.split(' ')[2]}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
